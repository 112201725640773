import * as React from "react";
import { Route } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Styles from "./styles/HeroImageNavigationStyles";
import { Paper, Grid, Container, Button, Box, Typography, Hidden, FormControl, InputLabel, Select, MenuItem, TextField, useMediaQuery, Theme, useTheme } from "@material-ui/core";
import * as queryString from "query-string";
import { Navigation } from "./Navigation";
import { ISearchOptions, OrderBy, QuickSearch, SearchOption } from "../../interfaces/search/ISearchOptions";
import { IInitialState, AppContext } from "../../contexts/AppContext";
import * as SearchService from "../../services/SearchService";
import { IVehicleSearchConfiguration } from "../../interfaces/search/IVehicleSearchConfiguration";
import { StyleContext } from "../../contexts/StyleContext";
import { AuctionSearchResult } from "../../services/AuctionService";
import Carousel from 'react-material-ui-carousel'
import { Media, useLotMedia } from "../../services/MediaService";
import { useSnackbar } from "notistack";
import { HomepageBillboardSlide } from "../homepage/HomepageBillboardSlide";
import GlobalAlert from "../notifications/GlobalAlert";

const HeroImageNavigation: React.FC = () => {
  const context = React.useContext(StyleContext);
  const classes = Styles.HeroImageNavigation(context.theme)();
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
  const appContext = React.useContext(AppContext);
  const bidderLatitude = appContext.bidderState.bidder?.address?.latitude;
  const bidderLongitude = appContext.bidderState.bidder?.address?.longitude;

  const [configuration, setConfiguration] = React.useState<IVehicleSearchConfiguration>();
  const [searchOptions, setSearchOptions] = React.useState<ISearchOptions>(SearchOption(bidderLatitude, bidderLongitude));

  const [auctionCount, setAuctionCount] = React.useState<number | null>(null);
  const [homepageStats, setHomepageStats] = React.useState<SearchService.HomepageCategoryStats | null>(null);
  const [billboardSlides, setBillboardSlides] = React.useState<Array<AuctionSearchResult>>([]);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (searchOptions.longitude === 0 && searchOptions.latitude === 0) {
      if (bidderLatitude && bidderLongitude) {
        setSearchOptions({
          ...searchOptions,
          latitude: bidderLatitude,
          longitude: bidderLongitude,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.bidderState.bidderLoaded]);

  React.useEffect(() => {
    SearchService.SearchConfiguration(searchOptions).then(data => {
      if (data.parsedBody !== undefined) {
        setConfiguration(data.parsedBody);
        if (auctionCount === null) {
          const count = Object.values(data.parsedBody.subscriptions).reduce((a, b) => a + b, 0);
          setAuctionCount(count);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions]);

  React.useEffect(() => {
    SearchService.GetHomepageStats().then(data => {
      if (data.parsedBody !== undefined) {
        setHomepageStats(data.parsedBody);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions]);

  React.useEffect(() => {
    SearchService.GetBillboard().then(data => {
      if (data.parsedBody !== undefined) {
        setBillboardSlides(data.parsedBody);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions]);



  const onFieldChange = (e: any) => {
    let value: string = "";
    if (e.target.value === "All") {
      value = "";
    } else {
      value = e.target.value;
    }

    switch (e.target.name) {
      case "vehicleType":
        setSearchOptions({ ...searchOptions, auction: [value] });
        break;
      case "make":
        setSearchOptions({ ...searchOptions, make: [value] });
        break;
      case "searchText":
        setSearchOptions({ ...searchOptions, searchText: value });
        break;
      default:
        break;
    }
  };

  const runSearch = (history: any) => {
    const searchLocation = {
      pathname: "/Search",
      search: searchOptions ? queryString.stringify(searchOptions) : "",
    };
    if (configuration !== undefined) {
      history.push(searchLocation);
    }
  };

  const SearchButton = () => (
    <>
      <Route
        render={({ history }) => (
          <Button type="button" fullWidth variant="contained" color={"primary"} onClick={() => runSearch(history)}>
            Find your vehicle
          </Button>
        )}
      />
    </>
  );

  const navigate = (history: any, type: string) => {
    const searchLocation = {
      pathname: "/search",
      search: "",
    };


    if (type == 'highValue') {
      searchLocation.search = `?quickSearch=${QuickSearch.HighValue}&orderBy=${OrderBy.PriceDesc}`;
    }
    else if (type == 'startsAndDrives') {
      searchLocation.search = `?quickSearch=${QuickSearch.StartsAndDrives}`;
    }
    else if (type == 'unrecorded') {
      searchLocation.search = `?damageCategory=X`;
    }
    else if (type == 'justAdded') {      
      searchLocation.search = `?quickSearch=${QuickSearch.RecentlyAdded}&orderBy=${OrderBy.RecentlyAdded}`;
    }
    else if (type == 'featured') {
      searchLocation.search = `?quickSearch=${QuickSearch.Featured}`;
    }

    history.push(searchLocation);

  };

  return (
    <AppContext.Consumer>
      {(context: IInitialState) => (
        <div className={classes.root}>
          <div className={classes.navigationRoot}>
            <Container maxWidth={"lg"} className={classes.navigationContainer}>
              <Navigation lightTheme={true} context={context} />
            </Container>
            <GlobalAlert />
          </div>

          <Container maxWidth={"lg"} style={{padding: '0', marginTop: '-10px'}} fixed className={classes.contentContainer}>
            <Grid container spacing={1} alignItems="center">

              <Grid item lg={12} md={12} sm={12} xs={false}>
                <Box display="flex" alignItems="center" className={classes.heroTextContainer}>
                  <Typography variant="h4" className={classes.heroText}>
                    The UK's Premier Online Salvage Auction Site
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={false}>
                <Paper className={classes.quickSearch} style={{border: "1px solid #ccc"}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box alignItems="center" width={1} component="span" fontSize="h5.fontSize" color="primary.main" fontWeight={500}>
                        {auctionCount === null && (
                          <CircularProgress className={classes.auctionCountLoading} color="primary" size={16} thickness={8} />
                        )}
                        {auctionCount !== null && <span>{auctionCount} </span>}
                        <span style={{ color: "black" }}>auction lots available</span>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="make">Vehicle Type</InputLabel>
                        <Select
                          fullWidth
                          id="vehicleType"
                          name="vehicleType"
                          value={searchOptions.auction}
                          onChange={value => onFieldChange(value)}
                          disabled={!configuration}
                        >
                          <MenuItem key={-1} value="All">
                            All
                          </MenuItem>
                          {configuration !== undefined &&
                            Object.keys(configuration.lotTypes).map((lotType, index) => {
                              return (
                                <MenuItem key={index} value={lotType}>
                                  {lotType}{" "}
                                  {Object.values(configuration.lotTypes)[index] > 0 ? `(${Object.values(configuration.lotTypes)[index]})` : ""}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="make">Make</InputLabel>
                        <Select fullWidth id="make" name="make" value={searchOptions.make} onChange={value => onFieldChange(value)}>
                          <MenuItem key={-1} value="All">
                            All
                          </MenuItem>
                          {configuration !== undefined &&
                            Object.keys(configuration.makes).map((make, index) => {
                              return (
                                <MenuItem key={index} value={make}>
                                  {make} {Object.values(configuration.makes)[index] > 0 ? `(${Object.values(configuration.makes)[index]})` : ""}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Route
                          render={({ history }) => (
                            <TextField
                              fullWidth
                              name="searchText"
                              label="Search e.g. Fiesta"
                              value={searchOptions.searchText}
                              onChange={value => onFieldChange(value)}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                  runSearch(history);
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <SearchButton />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item lg={8} md={8} sm={12} xs={false} style={{ paddingLeft: "15px", marginTop: "30px" }}>
                <Grid container spacing={2}
                  style={{
                    width: isMdWidth ? '100%' : '100%', // Adjust width based on screen size
                    paddingBottom: '15px'
                  }}>
                  <Grid item lg={2} md={6} sm={12}>
                    <Route render={({ history }) => (
                      <Button type="button" fullWidth variant="contained" className="responsive-button" color={"primary"} onClick={() => { navigate(history, 'highValue') }} style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
                        Highest Value
                      </Button>
                    )} />
                  </Grid>
                  <Grid item lg={2} md={6} sm={12}>
                    <Route render={({ history }) => (
                      <Button type="button" className="responsive-button" fullWidth variant="contained" color={"primary"} onClick={() => { navigate(history, 'featured') }} style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
                        Featured
                      </Button>
                    )} />
                  </Grid>
                  <Grid item lg={2} md={6} sm={12}>
                    <Route render={({ history }) => (
                      <Button type="button" className="responsive-button" fullWidth variant="contained" color={"primary"} onClick={() => { navigate(history, 'justAdded') }} style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
                        Just Added
                      </Button>
                    )} />
                  </Grid>
                  <Grid item lg={2} md={6} sm={12}>
                    <Route render={({ history }) => (
                      <Button type="button" className="responsive-button" fullWidth variant="contained" color={"primary"} onClick={() => { navigate(history, 'startsAndDrives') }} style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
                        Starts & Drives 
                      </Button>
                    )} />
                  </Grid>
                  <Grid item lg={2} md={6} sm={12}>
                    <Route render={({ history }) => (
                      <Button type="button" className="responsive-button" fullWidth variant="contained" color={"primary"} onClick={() => { navigate(history, 'unrecorded') }} style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
                        Unrecorded
                      </Button>
                    )} />
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ width: '100%' }}>
                  <Grid item style={{ width: '100%' }}>
                    <Carousel
                      interval={8000}
                      animation="slide"
                      fullHeightHover={true}
                      navButtonsProps={{
                        className: classes.carouselNav,
                      }}
                    >
                      {billboardSlides !== undefined &&
                        billboardSlides.map((item: AuctionSearchResult, index: number) => (
                          <HomepageBillboardSlide item={item} context={context}></HomepageBillboardSlide>
                        ))}
                    </Carousel>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Container>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default HeroImageNavigation;
