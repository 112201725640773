import * as React from "react";
import * as Styles from "../styles/SearchBarStyles";
import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ISearchBarOptionsProps } from "../../../interfaces/search/ISearchBarOptionProps";

export const SearchBarOption: React.FC<ISearchBarOptionsProps> = ({
  displayName,
  propertyName,
  disable,
  options,
  selectedItemNames,
  onSelectedEvent,
}) => {
  const classes = Styles.SearchBarStyles();

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState(() => {
    if (selectedItemNames != null && selectedItemNames.length > 0 && options) {
      return selectedItemNames.filter(x => x !== "");
    } else {
      // handle the case where options is null or undefined
      return [];
    }
  });
  const [selectedItems, setSelectedItems] = React.useState(displayName);

  React.useEffect(() => {
    if (options === undefined) return;
    setSelectedOptions(selectedItemNames);

    let selected = selectedItemNames.length > 0 ? Object.keys(options).filter(x => selectedItemNames.includes(x)) : [];
    setSelectedItems(selected.length > 0 ? selected.join(", ") : displayName);
  }, [selectedItemNames, options]);

  function handleMenuItemClick(event: any, index: any) {
    let updatedOptions = [...selectedOptions];

    if (index === -1) {
      setSelectedItems(displayName);
      updatedOptions = [];
      setOpen(false);
    } else if (updatedOptions.includes(index)) {
      updatedOptions = updatedOptions.filter(x => x !== index);
      setOpen(false);
    } else {
      updatedOptions.push(index);
      setOpen(false);
    }

    setSelectedOptions(updatedOptions);

    const selected = updatedOptions.length > 0
      ? Object.keys(options).filter((_, i) => updatedOptions.includes(_))
      : [];

    setSelectedItems(updatedOptions.length > 0 ? selected.join(", ") : displayName);
    onSelectedEvent(propertyName, selected);
    // setOpen(false);
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ButtonGroup
        size="medium"
        variant={selectedOptions.length > 0 && !selectedOptions.includes("") ? "contained" : "outlined"}
        color={selectedOptions.length > 0 && !selectedOptions.includes("") ? "primary" : "default"}
        ref={anchorRef}
        aria-label=""
        disabled={disable}
        style={{ height: "100%", backgroundColor: "white", width: "100%" }}
      >
        <Button onClick={handleToggle} style={{ flexGrow: 1, justifyContent: "flex-start", textAlign: "left" }}>
          {displayName === "Category" && selectedItems !== "Category" && "Category"} {selectedItems}
        </Button>
        <Button
          color={selectedOptions.length > 0 && !selectedOptions.includes("") ? "primary" : "default"}
          size="small"
          aria-owns={open ? "menu-list-grow" : ""}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ flexGrow: 0 }}
        >
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      <Popper className={classes.searchOptionList} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper id="menu-list-grow">

              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.searchOptionListScroll}>
                  <MenuItem key={-1} selected={selectedOptions !== undefined && selectedOptions != null && selectedOptions.length === 0} onClick={event => handleMenuItemClick(event, -1)}>
                    All
                  </MenuItem>

                  {options !== undefined && options != null && Object.keys(options).length > 0 && (

                    Object.keys(options).map((option, index) => (
                      <MenuItem key={index} selected={selectedOptions !== undefined && selectedOptions != null && selectedOptions.includes(option)} onClick={event => handleMenuItemClick(event, option)}>
                        {displayName === "Category" && "Category"} {option}{" "}
                        {Object.values(options)[index] > 0 ? `(${Object.values(options)[index]})` : ""}
                      </MenuItem>
                    ))

                  )}

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
